<template>
  <div class="toastBox">
    <transition-group name="toast-move" tag="div">
      <div v-for="snackbar in snackbarQueue" :key="snackbar.id" class="toast">
        <svg
          @click="removeSnackbar(snackbar.id)"
          style="cursor: pointer"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="#ef4444"
            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
          />
        </svg>
        <span>{{ snackbar.message.message }}</span>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["snackbarQueue"]),
  },
  methods: {
    removeSnackbar(id) {
      this.$store.commit("REMOVE_SNACKBAR", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.toastBox {
  position: fixed;
  top: 65px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10000000;

  .toast {
    width: 400px;
    background: #fef3f3;
    color: #ef4444;
    box-shadow: 0 0 5px rgba(7, 7, 7, 0.096);
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 0.4rem;
    position: relative;
    animation: moveleft 0.5s linear forwards;
    transform: translateX(100%);
    margin-bottom: 1rem;
    overflow: hidden;
    border: 1px solid #fca5a5;
    @media (max-width: 576px) {
      width: 100%;
    }
    span {
      width: 90%;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #e71111;
      animation: shrink 6s linear forwards;
    }
  }
}

/* Transition Animations */
.toast-move-enter-active,
.toast-move-leave-active {
  transition: all 0.5s ease;
}

.toast-move-enter {
  transform: translateX(100%);
  opacity: 0;
}

.toast-move-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Animations */
@keyframes shrink {
  100% {
    width: 0%;
  }
}

@keyframes moveleft {
  100% {
    transform: translateX(0);
  }
}
</style>
